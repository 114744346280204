document.addEventListener('DOMContentLoaded', function () {
    const button = document.getElementById('menu-button');
    const menu = document.getElementById('nav-menu');

    if (button && menu) {
        button.addEventListener('click', function () {
            button.classList.toggle('is-active');
            menu.classList.toggle('is-active');
        });
    }
});
