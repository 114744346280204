import './scripts/navbar';
import './styles.scss';
import './views/components';
import Buefy from 'buefy'
import Vue from 'vue';

Vue.use(Buefy, {
    defaultIconPack: 'fa',
    defaultDayNames: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
    defaultMonthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
});

const Application = new Vue({
});

Application.$mount('#virtual-doc');
